import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

export default function(){
	function quemSomos(){
		const tl=gsap.timeline({
			scrollTrigger:{
				trigger:"#somos h3",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			}
		})
		gsap.from("#somos .text-2",{
			scrollTrigger:{
				trigger:"#somos .text-2",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			},
			opacity:0,
			yPercent: 30,
			duration:1,
		})
		tl.from("#somos .text-1 ",{
			opacity:0,
			yPercent: 30,
			duration:1,
			// stagger:0.1,
		})
		.from("#somos img", {
			opacity:0,
			xPercent: 100,
			duration:1,
		},0)
	}

	function bannerHome(){
		const tl=gsap.timeline({
			
		})

		tl.from("#banner-homer .banner-titulo-1", {
			opacity:0,
			yPercent: 30,
			duration:1,

		})
		.from("#banner-homer .banner-titulo-2", {
			opacity:0,
			yPercent: 30,
			duration:1,

		},"<+=0.2")
		.from("#banner-homer .detalhe-titulo-l", {
			opacity:0,
			x: -30,
			duration:1,

		},"<+=0.2")
		.from("#banner-homer .detalhe-titulo-r", {
			opacity:0,
			x: 30,
			duration:1,

		},"<+=0.2")
		.from("#banner-homer h3", {
			opacity:0,
			yPercent: 30,
			duration:1,

		},"<+=0.2")

	}

	function carrossell(){
		gsap.from("#swiper-banner .swiperBanner",{
			scrollTrigger:{
				trigger:"#swiper-banner .swiperBanner",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			},
			opacity:0,
			yPercent: 30,
			duration:1,
			ease: "power1.out",
		})
	}

	function marcas(){
		const tl=gsap.timeline({
			scrollTrigger:{
				trigger:"#marcas_solucionadas",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			}
		})
		gsap.set('#marcas_solucionadas .grid-titulo h2',{
			clipPath:"inset(0 100% 0 0)",
			opacity:0,
			y:20,
			
		})
		tl.to("#marcas_solucionadas .grid-titulo h2",{
			clipPath:"inset(0 0% 0 0)",
			opacity:1,
			duration:2.2,
			ease: "power1.out",
			y:0,
		})
		.from("#marcas_solucionadas .grid-imagens div",{
			opacity:0,
			y:30,
			stagger: 0.2,
		},"-=0.7")
	}

	function cutiveImg(){
		gsap.from("#banner",{
			scrollTrigger:{
				trigger:"#banner",
				start: "top 90%",
				// markers: true,
				// scrub:1,
			},
			opacity:0,
			yPercent: 10,
			scale:0.8,
			duration:1,
			ease: "power1.out",
		})
	}

	quemSomos()
	bannerHome()
	carrossell()
	marcas()
	cutiveImg()
}

